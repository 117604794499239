<template>
  <article class="message is-success is-small">
    <div class="message-body">アカウントの確認が完了しました。</div>
  </article>
  <div class="content">
    <p>宅配ボックスの設置住所を登録してください。</p>
    <button class="button btn-submit" @click="toRegistLocker">次へ</button>
  </div>
</template>

<script>
export default {
  name: "LockerPreRegist",
  methods: {
    toRegistLocker() {
      this.$router.push({
        path: "/locker/regist",
      })
    },
  },
}
</script>

<style scoped>
.content {
  text-align: center;
}
</style>
